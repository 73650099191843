import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import _ from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { useFetcher, useNavigate } from "react-router";
import { IconUser } from "~/components/icons";
import Link from "~/components/link";
import type { GlobalSearchQuery } from "~/types/api";
import { plural } from "~/utils/formatting";

export default function CommandPalette() {
  const [open, setOpen] = useState(false);
  const [query, _setQuery] = useState("");
  const timerRef = useRef<number>(null);
  const fetcher = useFetcher<GlobalSearchQuery>();

  const setQuery = (query: string) => {
    timerRef.current && clearTimeout(timerRef.current);
    _setQuery(query);
    if (query.length >= 3) {
      timerRef.current = window.setTimeout(() => {
        fetcher.load(`/resources/search?search=${query}`);
      }, 200);
    }
  };

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      if (event.key === "k" && (event.ctrlKey || event.metaKey)) {
        setOpen((open) => !open);
      }
    };
    window.addEventListener("keydown", keydown);
    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, []);
  const navigate = useNavigate();
  const results = _.groupBy(fetcher.data?.globalSearch || [], "type");

  const switchUser = (id: string) =>
    fetcher.submit(
      { employeeId: id, href: window.location.href },
      { method: "post", action: "/admin/users?index" }
    );

  return (
    <Transition
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-[2000]" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox<{ value: string; label: string }>
                onChange={(value) => {
                  if (value) {
                    const [type, id] = value.value.split("-");
                    const match = fetcher.data?.globalSearch.find(
                      (r) => r.type === type && r.id === id
                    );
                    if (match) {
                      setOpen(false);
                      navigate(match.url);
                    }
                  }
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-5 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <ComboboxInput<{ value: string; label: string }>
                    autoFocus
                    className="h-16 w-full border-0 bg-transparent !pl-12 !pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 focus-visible:outline-none"
                    placeholder="Search..."
                    displayValue={(value) => value?.label}
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {query.length >= 3 && Object.keys(results).length > 0 && (
                  <ComboboxOptions
                    static
                    className="!m-0 max-h-[75vh] scroll-py-2 list-none divide-y divide-gray-100 overflow-y-auto !p-0 py-2 text-gray-800"
                  >
                    {Object.keys(results).map((group) => (
                      <li key={group}>
                        <div className="px-5 py-3 font-semibold text-gray-500">
                          {_.startCase(plural(group, 2))}
                        </div>
                        <ul className="list-none !p-0">
                          {results[group].map((result) => (
                            <ComboboxOption
                              key={`${result.type}-${result.id}`}
                              value={{
                                value: `${result.type}-${result.id}`,
                                label: result.name
                              }}
                              className={({ focus }) =>
                                clsx(
                                  "flex cursor-default select-none items-center justify-between px-5 py-3",
                                  focus && "bg-indigo-600 text-white"
                                )
                              }
                            >
                              {({ focus }) => (
                                <>
                                  <div>{result.name}</div>
                                  {result.type === "employee" && (
                                    <Link
                                      onMouseDown={(event) => {
                                        // Otherwise the combobox selects
                                        event.preventDefault();
                                      }}
                                      to={(event) => {
                                        event.preventDefault();
                                        switchUser(result.id);
                                      }}
                                    >
                                      <IconUser
                                        fixed
                                        className={focus ? "text-white" : ""}
                                      />
                                    </Link>
                                  )}
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ComboboxOptions>
                )}

                {query !== "" && fetcher.data?.globalSearch.length === 0 && (
                  <p className="px-5 py-3 italic text-gray-500">
                    No results found.
                  </p>
                )}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
