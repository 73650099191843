import type { ReactNode } from "react";
import type React from "react";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useSubmit } from "react-router";
import { matchPath } from "react-router";
import Badge from "~/components/badge";
import DropdownContent from "~/components/dropdown-content";
import DropdownDivider from "~/components/dropdown-divider";
import DropdownHeader from "~/components/dropdown-header";
import DropdownLink from "~/components/dropdown-link";
import ModalFeatureForm from "~/components/features/modal-form";
import {
  IconCircle,
  IconCircleSolid,
  IconComments,
  IconDashboard,
  IconEmail,
  IconExclamation,
  IconHelp,
  IconHome,
  IconList,
  IconLogout,
  IconReply,
  IconSettings
} from "~/components/icons";
import Link from "~/components/link";
import NavBar from "~/components/nav-bar";
import NavDropdown from "~/components/nav-dropdown";
import NavLink from "~/components/nav-link";
import CommandPalette from "~/components/palette";
import SocketWrapper from "~/components/socket-wrapper";
import { NavContext } from "~/contexts";
import { isAllowed, useAllowed, useCurrentUser } from "~/utils/auth";
import { useIsStale } from "~/utils/hooks";

type Props = {
  children: ReactNode;
  isShadowing: boolean;
};

export default function SiteNav({ children, isShadowing }: Props) {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const [addingFeature, setAddingFeature] = useState(false);
  const [subnav, setSubnav] = useState<HTMLDivElement | null>(null);
  const subnavRef = useRef<HTMLDivElement | null>(null);
  const submit = useSubmit();
  const stale = useIsStale();

  useEffect(() => {
    setSubnav(subnavRef.current);
  }, []);

  const switchMode = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (window.location.href.startsWith("https://intranet")) {
      window.location.href = window.location.href.replace(
        "https://intranet.petefowler.com",
        "http://localhost:3000"
      );
    } else {
      window.location.href = window.location.href.replace(
        "http://localhost:3000",
        "https://intranet.petefowler.com"
      );
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  const logout = () => {
    formRef.current?.submit();
  };

  const stopShadowing = () => {
    if (typeof document === "undefined") {
      return;
    }
    const fd = new FormData();
    fd.set("intent", "stop-shadowing");
    fd.set("href", window.location.href);
    submit(fd, { method: "post", action: "/" });
  };

  const isAdmin = useAllowed("Admin/Developer");
  const version = import.meta.env.VITE_SENTRY_RELEASE || "N/A";
  const context = useMemo(() => ({ stale, subnav }), [stale, subnav]);
  const showStaleWarning = stale && currentUser.login === "DM";

  return (
    <SocketWrapper>
      <form action="/logout" method="post" ref={formRef} />
      {currentUser.roles.includes("Feature Flag: Global Search") && (
        <CommandPalette />
      )}
      <NavContext.Provider value={context}>
        <Fragment>
          {currentUser.pendingTimesheets && (
            <div className="bg-red-500 px-14 py-4 text-center text-white">
              Please submit your timesheets for last month ASAP! Please submit
              your timesheets for last month ASAP (see the{" "}
              <strong>Submit</strong> button at the upper right of your
              timesheets page)
            </div>
          )}
          {showStaleWarning && (
            <div className="fixed bottom-10 right-10 z-50 w-[400px] rounded-md border border-blue-300 bg-blue-50 p-8 shadow-md">
              <h5 className="mt-0 text-blue-500">
                <IconExclamation /> Update Available
              </h5>
              <Link to={() => window.location.reload()}>
                A new major version of the intranet is available, save your work
                and reload the page or click here to load the new version.
              </Link>
            </div>
          )}
          <header className="print:hidden">
            <NavBar
              rightMenu={
                <>
                  {isShadowing && (
                    <NavLink to={stopShadowing}>
                      Shadowing {currentUser.fullname}
                    </NavLink>
                  )}
                  {currentUser.id === "5" && (
                    <NavLink to="/notifications">
                      {currentUser.unreadNotifications ? (
                        <IconCircleSolid className="text-red-500" />
                      ) : (
                        <IconCircle className="text-white" />
                      )}
                    </NavLink>
                  )}
                  <NavDropdown noCaret label={<IconSettings />}>
                    <DropdownLink
                      to="/settings"
                      label={
                        <span>
                          <IconSettings fixed /> Options
                        </span>
                      }
                    />
                    <DropdownLink
                      external
                      to="https://gmail.com"
                      target="_blank"
                    >
                      <IconEmail fixed /> Gmail
                    </DropdownLink>
                    <DropdownLink
                      to="/auto-saves"
                      label={
                        <span>
                          <IconList fixed /> Auto Saves
                        </span>
                      }
                    />
                    <DropdownLink
                      to={() => setAddingFeature(true)}
                      label={
                        <span>
                          <IconComments fixed /> Request a Feature/Bug Fix
                        </span>
                      }
                    />
                    {isAdmin && (
                      <>
                        <DropdownDivider />
                        <DropdownLink
                          external
                          to="https://api.petefowler.com/oban"
                        >
                          <IconDashboard fixed /> Oban Queue
                        </DropdownLink>
                        <DropdownLink to={switchMode}>
                          <IconReply fixed /> Switch Dev/Production Mode
                        </DropdownLink>
                        <DropdownContent>
                          <IconHelp fixed /> Version: {version}
                        </DropdownContent>
                        {import.meta.env.DEV && (
                          <>
                            <DropdownLink
                              external
                              target="_blank"
                              to="https://pfcs.dev:4000/mailbox"
                            >
                              <IconEmail /> Test Mailbox
                            </DropdownLink>
                            <DropdownLink
                              external
                              target="_blank"
                              to="https://pfcs.dev:4000/graphiql"
                            >
                              <IconList /> GraphiQL
                            </DropdownLink>
                          </>
                        )}
                      </>
                    )}
                    <DropdownDivider />
                    <DropdownHeader>
                      Logged in as {currentUser.fullname}
                    </DropdownHeader>
                    <DropdownLink to={logout}>
                      <IconLogout fixed /> Logout
                    </DropdownLink>
                  </NavDropdown>
                </>
              }
            >
              <NavLink
                match={
                  location.pathname === "/" ||
                  ["scoreboard/me", "home"].some((x) =>
                    matchPath(`/${x}/*`, location.pathname)
                  )
                }
                to="/"
                label={<IconHome />}
              />
              <NavLink
                matchPath="/scoreboard"
                to="/scoreboard"
                label={<IconDashboard />}
              />
              <NavLink
                match={["library", "features", "training"].some((x) =>
                  matchPath(`/${x}/*`, location.pathname)
                )}
                to="/library"
                label="Library"
              />
              {["STAFF", "CONSULTANT"].includes(currentUser.userType) && [
                <NavLink
                  to="/contacts"
                  exact={false}
                  label="Contacts"
                  key="contacts"
                />,
                <NavLink
                  key="sales"
                  to="/sales"
                  match={[
                    "opportunities",
                    "metrics/sales",
                    "seminars",
                    "marketing",
                    "sales",
                    "campaigns",
                    "mailings",
                    "content"
                  ].some((x) => matchPath(`/${x}/*`, location.pathname))}
                  label="Marketing/Sales"
                />
              ]}
              <NavLink
                match={["projects", "kanban"].some((x) =>
                  matchPath(`/${x}/*`, location.pathname)
                )}
                to="/projects"
                label="Projects"
              />
              <NavLink exact={false} to="/timesheets" label="Timesheets" />
              <NavLink
                exact={false}
                to="/actions"
                label={
                  <span>
                    Kanban/Actions
                    <span>
                      {" "}
                      {currentUser.pastDueTaskCount > 0 && (
                        <Badge
                          title={`${currentUser.pastDueTaskCount} past due`}
                          mode="danger"
                          label={currentUser.pastDueTaskCount}
                        />
                      )}{" "}
                      {currentUser.todayTaskCount > 0 && (
                        <Badge
                          title={`${currentUser.todayTaskCount} past due`}
                          mode="warning"
                          label={currentUser.todayTaskCount}
                        />
                      )}
                    </span>
                  </span>
                }
              />

              {["STAFF", "CONSULTANT"].includes(currentUser.userType) && (
                <NavLink
                  match={["ar", "payments", "invoices/payors"].some((x) =>
                    matchPath(`/${x}/*`, location.pathname)
                  )}
                  to="/ar"
                  label="A/R"
                />
              )}
              {(currentUser.userType === "CONSULTANT" ||
                currentUser.showManagement ||
                isAllowed(
                  [
                    "Admin/Developer",
                    "Team: Management",
                    "KPIs: Acquisitions",
                    "KPIs: Areas",
                    "Team: Leadership",
                    /^Employees:/
                  ],
                  currentUser
                )) && (
                <NavLink
                  to="/management"
                  match={[
                    "management",
                    "charts",
                    "topics",
                    "hiring",
                    "kpis",
                    "payroll",
                    "employees",
                    "admin"
                  ].some((x) => matchPath(`/${x}/*`, location.pathname))}
                  label="Management"
                />
              )}
              {currentUser.invoiceReviewCount > 0 && (
                <NavLink exact={false} to="/invoices/review" label="Invoices" />
              )}
            </NavBar>

            <div ref={subnavRef} className="subnav-default" />
          </header>
          <div className="container-fluid react-wrapper">{children}</div>

          {showStaleWarning && <div className="h-[100px]" />}
          {addingFeature && (
            <ModalFeatureForm onClose={() => setAddingFeature(false)} />
          )}
        </Fragment>
      </NavContext.Provider>
    </SocketWrapper>
  );
}
